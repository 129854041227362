// src/components/Dashboard.js
import React, { useState } from 'react';
import PackageList from './PackageList';
import CreatePackage from './CreatePackage';

const TourManagement = () => {
  const [showCreatePackage, setShowCreatePackage] = useState(false); // State to control visibility of CreatePackage

  const handleClose = () => {
    setShowCreatePackage(false); // Close the CreatePackage modal
  };

  const handleOpen = () => {
    setShowCreatePackage(true); // Open the CreatePackage modal
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="flex justify-between p-4">
        <h1 className="text-2xl font-bold">Tour Management</h1>
        <button onClick={handleOpen} className="bg-blue-500 text-white px-4 py-2 rounded">
          Create Package
        </button>
      </div>

      {/* Modal for CreatePackage */}
      {showCreatePackage && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-4 w-1/3 md:w-1/3 max-h-[100vh] overflow-y-auto"> {/* Adjust height and add scroll */}
            <h2 className="text-xl font-semibold mb-4">Create New Package</h2>
            <CreatePackage onClose={handleClose} />
            <button onClick={handleClose} className="mt-4 bg-red-500 text-white px-4 py-2 rounded">
              Close
            </button>
          </div>
        </div>
      )}

      <div className="flex-1 overflow-y-auto p-4">
        <PackageList />
      </div>
    </div>
  );
};

export default TourManagement;
