import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineDashboard, AiOutlineTeam, AiOutlineAppstore, AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';

const Sidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex bg-gray-800">
      {/* Sidebar */}
      <div className={`fixed left-0 top-0 w-64 bg-gray-800 text-white min-h-screen p-5 z-50 transition-transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'}`}>
        <h2 className="text-2xl font-bold mb-8">Admin Dashboard</h2>
        <nav className="space-y-4">
          <Link to="/admin-dashboard" className="flex items-center space-x-2 p-2 hover:bg-gray-700 rounded">
            <AiOutlineDashboard />
            <span>Dashboard</span>
          </Link>
          <Link to="/tours" className="flex items-center space-x-2 p-2 hover:bg-gray-700 rounded">
            <AiOutlineAppstore />
            <span>Tour Management</span>
          </Link>
          <Link to="/team" className="flex items-center space-x-2 p-2 hover:bg-gray-700 rounded">
            <AiOutlineTeam />
            <span>Team Management</span>
          </Link>
        </nav>
        
      </div>

      {/* Main Content Area */}
      <div className={`flex-grow  transition-all duration-300 ${isSidebarOpen ? 'ml-64' : 'ml-0'}`}>
        <button 
          className="fixed top-2  z-50 p-2  bg-white text-black rounded transition-transform duration-300" 
          onClick={toggleSidebar}
        >
          {isSidebarOpen ? <AiOutlineClose className="text-2xl" /> : <AiOutlineMenu className="text-2xl" />}
        </button>
        
       
        
      </div>
    </div>
  );
};

export default Sidebar;
