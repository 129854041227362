// src/components/Modal.js
import React, { useEffect, useState } from 'react';

const Modal = ({ isOpen, onClose, onConfirm, title, children }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
    } else {
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 300); // Match this duration with the CSS transition duration

      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  if (!isVisible) return null; // Prevent rendering if not visible

  return (
    <div className="fixed inset-0 flex justify-center items-center z-50 bg-black bg-opacity-50">
      <div className={`bg-white rounded-lg p-6 w-11/12 md:w-1/3 transform transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0'}`}>
        <h2 className="text-xl font-semibold mb-4">{title}</h2>
        {children}
        
      </div>
    </div>
  );
};

export default Modal;
