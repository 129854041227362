import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import Dashboard from './pages/Dashboard';
import TourManagement from './pages/TourManagement';
import TeamManagement from './pages/TeamManagement';
import Login from './pages/Login';
import Signup from './pages/Signup';
import ForgotPassword from './pages/ForgotPassword';
import Header2 from './components/Header2';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [role, setRole] = useState(null);

  // Load authentication state from local storage
  useEffect(() => {
    const token = localStorage.getItem("token");
    const storedRole = localStorage.getItem("role");

    if (token && storedRole) {
      setIsAuthenticated(true);
      setRole(storedRole);
    }
  }, []);

  const handleLogin = (token, role) => {
    localStorage.setItem("token", token);
    localStorage.setItem("role", role);
    setIsAuthenticated(true);
    setRole(role);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    setIsAuthenticated(false);
    setRole(null);
  };

  const renderNavbar = () => {
    if (!isAuthenticated) {
      return <Header2 />;
    } else if (role === "admin") {
      return <Header onLogout={handleLogout} />;
    }
  };

  return (
    <Router>
      {renderNavbar()}
      <div className="flex">
        {isAuthenticated && role === "admin" && <Sidebar />}
        <div className="flex-1 flex flex-col">
          <main className="p-6 bg-gray-100 min-h-screen">
            <Routes>
              {!isAuthenticated ? (
                <>
                  <Route path="/admin-login" element={<Login onLogin={handleLogin} />} />
                  <Route path="/admin-sign" element={<Signup />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="*" element={<Navigate to="/admin-login" />} />
                </>
              ) : role === "admin" ? (
                <>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/tours" element={<TourManagement />} />
                  <Route path="/team" element={<TeamManagement />} />
                  <Route path="*" element={<Navigate to="/" />} />
                </>
              ) : (
                <Route path="*" element={<Navigate to="/admin-login" />} />
              )}
            </Routes>
          </main>
        </div>
      </div>
    </Router>
  );
}

export default App;
