import React, { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
} from 'recharts';
import Card from '../components/Card';
import axios from 'axios'; // Import axios for making API calls

const Dashboard = () => {
  let URL='https://soaraway-api.zapto.org'
  // State for card data, initialized as an empty array
  const [cardData, setCardData] = useState([]);
  const [overviewData, setOverviewData] = useState([]); // State for overview data

  // Sample data for revenue and profit charts
  const revenueData = [
    { name: 'Online Sales', value: 4000 },
    { name: 'Travel Packages', value: 3000 },
    { name: 'Merchandise', value: 2000 },
    { name: 'Consulting', value: 1000 },
  ];

  const profitData = [
    { month: 'Jan', profit: 2400 },
    { month: 'Feb', profit: 1398 },
    { month: 'Mar', profit: 9800 },
    { month: 'Apr', profit: 3908 },
    { month: 'May', profit: 4800 },
    { month: 'Jun', profit: 3800 },
    { month: 'Jul', profit: 4300 },
  ];

  // Fetching data from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch card data from the API
        const response = await axios.get(`${URL}/dashboard/dashboard`);
        
        // Convert object to array format
        const fetchedData = response.data;

        // Check if fetchedData is an object
        if (typeof fetchedData === 'object' && fetchedData !== null) {
          // Map the object to an array
          const formattedData = Object.keys(fetchedData).map(key => ({
            title: key.replace(/([A-Z])/g, ' $1'), // Optional: format keys to have spaces
            value: fetchedData[key]
          }));

          setCardData(formattedData);
          setOverviewData(formattedData)
        } else {
          console.error('Expected an object for card data:', fetchedData);
        }
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    };

    fetchData();
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <div className="p-6">
      <h1 className="text-3xl font-semibold mb-6">Dashboard</h1>
      
      {/* Cards Section */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
        {cardData.map((card) => (
          <Card key={card.title} title={card.title} value={card.value} />
        ))}
      </div>

      {/* Overview Bar Chart */}
      <h2 className="text-2xl font-semibold mb-4">Overview</h2>
      <div className="h-64 mb-6">
        <ResponsiveContainer>
          <BarChart data={overviewData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="title" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="value" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      {/* Revenue Pie Chart */}
      <h2 className="text-2xl font-semibold mb-4">Revenue Overview</h2>
      <div className="h-64 mb-6">
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={revenueData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={80}
              fill="#8884d8"
              label
            />
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </div>

      {/* Profit Bar Chart */}
      <h2 className="text-2xl font-semibold mb-4">Profit Overview</h2>
      <div className="h-64 mb-6">
        <ResponsiveContainer>
          <BarChart data={profitData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="profit" fill="#2196F3" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Dashboard;
