import React from 'react';

const DataTable = ({ columns, data }) => {
  return (
    <div className="overflow-x-auto bg-white shadow-lg rounded-lg">
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            {columns.map((column) => (
              <th
                key={column}
                className="py-3 px-6 text-left text-gray-600 font-semibold uppercase tracking-wider border-b"
              >
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex} className="border-b hover:bg-gray-100">
              {columns.map((column, colIndex) => (
                <td key={colIndex} className="py-4 px-6">
                  {row[column]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
