// src/components/PackageList.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CreatePackage from './CreatePackage';

const PackageList = () => {
  let URL='https://soaraway-api.zapto.org'
  const [packages, setPackages] = useState([]);
  const [editPackage, setEditPackage] = useState(null); // State to manage editing
  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const [isEditing, setIsEditing] = useState(false); // Flag for editing mode

  useEffect(() => {
    fetchPackages();
  }, []);

  const fetchPackages = async () => {
    try {
      const response = await axios.get(`${URL}/package/packages`); // Update with your API endpoint
      setPackages(response.data);
    } catch (error) {
      console.error('Error fetching packages:', error);
    }
  };

  const deletePackage = async (id) => {
    try {
      await axios.delete(`${URL}/package/packages/${id}`);
      fetchPackages(); // Refresh the package list
    } catch (error) {
      console.error('Error deleting package:', error);
    }
  };

  const startEditing = (pkg) => {
    setEditPackage(pkg);
    setIsEditing(true);
    setShowModal(true); // Open modal when starting editing
  };

  const handleModalClose = () => {
    setEditPackage(null);
    setIsEditing(false);
    setShowModal(false); // Close modal
    fetchPackages(); // Refresh the package list after editing
  };

  const handleCreatePackageOpen = () => {
    setEditPackage(null);
    setIsEditing(false);
    setShowModal(true); // Open modal for creating a new package
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold">Packages</h1>
      <button onClick={handleCreatePackageOpen} className="bg-blue-500 text-white px-4 py-2 rounded mb-4">
        Create Package
      </button>
      <table className="min-w-full mt-4 border-collapse border border-gray-300">
        <thead>
          <tr className="bg-gray-200">
            <th className="border border-gray-300 p-2">Name</th>
            <th className="border border-gray-300 p-2">Category</th>
            <th className="border border-gray-300 p-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {packages.map((pkg) => (
            <tr key={pkg._id} className="hover:bg-gray-100">
              <td className="border border-gray-300 p-2">{pkg.name}</td>
              <td className="border border-gray-300 p-2">{pkg.category}</td>
              <td className="border border-gray-300 p-2">
                <button onClick={() => startEditing(pkg)} className="bg-yellow-500 text-white rounded px-2 py-1 mr-2">Edit</button>
                <button onClick={() => deletePackage(pkg._id)} className="bg-red-500 text-white rounded px-2 py-1">Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal for CreatePackage */}
      {showModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-4 w-1/4 max-h-[60vh] overflow-y-auto"> {/* Adjusted width and height */}
            <h2 className="text-xl font-semibold mb-4">{isEditing ? 'Edit Package' : 'Create New Package'}</h2>
            <CreatePackage
              packageData={isEditing ? editPackage : null} // Pass data for editing
              onClose={handleModalClose} // Handle modal close
            />
            <button onClick={handleModalClose} className="mt-4 bg-red-500 text-white px-4 py-2 rounded">
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PackageList;
