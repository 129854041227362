import React from 'react';
import { AiOutlineLogout } from 'react-icons/ai';

const Header2 = () => {
  return (
    <header className="flex justify-between items-center p-4 bg-white shadow sticky z-40 left-0 top-0">
      <h1 className="text-xl font-semibold mx-24">Soaraway Dashboard</h1>
     
    </header>
  );
};

export default Header2;
