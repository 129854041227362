import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Breadcrumb from '../pages/Breadcrumb';
import Logo from '../images/SA-Logo.png';
import { FaQuoteLeft } from 'react-icons/fa';
import axios from 'axios'; // Importing Axios

const SignIn = ({onLogin}) => {
  let URL='https://soaraway-api.zapto.org'
  const navigate = useNavigate(); // Hook for navigation

  const handleSubmit = async (e) => {
    e.preventDefault();

    const email = e.target.email.value;
    const password = e.target.password.value;

    try {
      const response = await axios.post(`${URL}/auth/login`, { // Replace with your API endpoint
        email,
        password,
      });
      console.log(response.data);

      // Assuming the token and role are in response.data
      localStorage.setItem('token', response.data.token); // Store token in localStorage
      localStorage.setItem('role', response.data.role); // Store role in localStorage
      onLogin(response.data.token,response.data.role);

      // Check user role and navigate accordingly
      const userRole = localStorage.getItem('role');
      if (userRole === 'admin') {
        navigate('/admin-dashboard'); // Navigate to admin dashboard
      } else {
        navigate('/user-dashboard'); // Navigate to user dashboard
      }
    } catch (error) {
      // Handle error
      console.error('Error during login:', error);
      alert(error.response?.data?.message || 'Login failed! Please check your credentials.'); // Display error message to the user
    }
  };

  return (
    <>
      <Breadcrumb pageName="Sign In" />
      <div className="rounded-sm border border-stroke bg-white shadow-default ">
        <div className="flex flex-wrap items-center ">
          <div className="hidden w-full xl:block xl:w-1/2 ">
            <div className="py-17.5 px-26 text-center">
              <Link className="mb-5.5 inline-block" to="/">
                <img className="" src={Logo} alt="Logo" height={200} width={200} />
              </Link>

              <p className="2xl:px-20 px-16 font-semibold flex items-center space-x-2">
                <FaQuoteLeft className="text-3xl text-black -mt-20 opacity-70 group-hover:text-yellow-300 transition-colors duration-300" />
                <span className="text-black opacity-90 px-4 group-hover:text-yellow-400 transition-colors duration-300">
                  Embark on Unforgettable Journeys and Create Memories That Will Last a Lifetime!
                </span>
                <FaQuoteLeft className="text-3xl mt-16 text-black opacity-70 transform rotate-180 group-hover:text-yellow-300 transition-colors duration-300" />
              </p>

              <span className="mt-15 inline-block">
                {/* SVG Code Here */}
              </span>
            </div>
          </div>

          <div className="w-full xl:w-1/2 ">
            <div className="py-10 px-6">
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Email address
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email" // Add name attribute for form handling
                    required
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-500"
                  />
                </div>
                <div>
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                    Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    name="password" // Add name attribute for form handling
                    required
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-500"
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    className="w-full py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                  >
                    Sign In
                  </button>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input type="checkbox" id="remember" className="mr-2" />
                    <label htmlFor="remember" className="text-sm">
                      Remember Me
                    </label>
                  </div>
                  <Link to="/forgot-password" className="text-sm text-blue-600 hover:underline">
                    Forgot Password?
                  </Link>
                  
                </div>
                <p>Donn't Have Anhy Account ?
                    <Link to='/admin-sign' className='text-blue-500'>Sign Up</Link>
                  </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
