import React from 'react';
import { AiOutlineLogout } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

const Header = ({onLogout}) => {
    const navigate=useNavigate()
    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("role");
    
        // Call onLogout to update parent state and redirect to login or home
        onLogout();
    
        // Redirect to login or home page
        navigate("/admin-login");
      };
    
  return (
    <header className="flex justify-between items-center p-4 bg-white shadow sticky z-40 left-0 top-0">
      <h1 className="text-xl font-semibold mx-24">Soaraway Dashboard</h1>
      <button className="flex items-center space-x-2 text-gray-600 hover:text-red-600" onClick={handleLogout}>
        <AiOutlineLogout />
        <span >Logout</span>
      </button>
    </header>
  );
};

export default Header;
