import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataTable from '../components/DataTable';
import Modal from '../components/modals/TeamModal';

const TeamManagement = () => {
  let URL='https://soaraway-api.zapto.org'
  const [teamData, setTeamData] = useState([]);
  const [formData, setFormData] = useState({
    Name: '',
    Role: '',
    Email: '',
    Status: 'Active',
    ProfilePic: null,
  });
  const [editMode, setEditMode] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState(null);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state

  const teamColumns = ['Profile', 'Name', 'Role', 'Email', 'Status', 'Actions'];

  useEffect(() => {
    fetchTeamData();
  }, []);

  const fetchTeamData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${URL}/team/team`);
      setTeamData(response.data);
    } catch (error) {
      console.error('Error fetching team data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'ProfilePic') {
      setFormData((prev) => ({ ...prev, [name]: files[0] })); 
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Ensure all required fields are filled out
    if (formData.Name && formData.Role && formData.Email) {
      const newMember = new FormData();
      newMember.append('name', formData.Name);
      newMember.append('email', formData.Email);
      newMember.append('position', formData.Role);
      newMember.append('status', formData.Status);
      
      // Check if ProfilePic is present before appending
      if (formData.ProfilePic) {
        newMember.append('profile_pic', formData.ProfilePic);
      } else {
        console.error('No Profile Picture selected'); // Log error if no picture is selected
      }
  
      setLoading(true); // Start loading
      try {
        const response = editMode
          ? await axios.put(`${URL}/team/team/${currentId}`, newMember, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
          : await axios.post(`${URL}/team/team`, newMember, {
              headers: { 'Content-Type': 'multipart/form-data' },
            });
  
        // Close the modal after successfully saving
        if (editMode) {
          setUpdateModalOpen(false); // Close the modal on successful update
        }
  
        const updatedMembers = editMode 
          ? teamData.map(member => member._id === currentId ? response.data.member : member)
          : [...teamData, response.data.member];
  
        setTeamData(updatedMembers);
        resetForm();
      } catch (error) {
        console.error('Error saving team member:', error);
        alert('An error occurred while saving the member. Please try again.');
      } finally {
        setLoading(false); // End loading
      }
    } else {
      alert('Please fill in all required fields'); // Alert if fields are missing
    }
  };
  
  const handleDelete = async (id) => {
    setLoading(true); // Start loading
    try {
      await axios.delete(`${URL}/team/team/${id}`);
      setTeamData((prev) => prev.filter(member => member._id !== id));
      setDeleteModalOpen(false);
    } catch (error) {
      console.error('Error deleting team member:', error);
    } finally {
      setLoading(false); // End loading
    }
  };

  const handleEdit = (id) => {
    const memberToEdit = teamData.find(member => member._id === id);
    setFormData({
      Name: memberToEdit.name,
      Role: memberToEdit.position,
      Email: memberToEdit.email,
      Status: memberToEdit.status,
      ProfilePic: null, // Reset ProfilePic for new upload
    });
    setCurrentId(id);
    setEditMode(true);
    setUpdateModalOpen(true);
  };

  const resetForm = () => {
    setFormData({ Name: '', Role: '', Email: '', Status: 'Active', ProfilePic: null });
    setEditMode(false);
    setCurrentId(null);
  };

  const openDeleteModal = (id) => {
    setMemberToDelete(id);
    setDeleteModalOpen(true);
  };

  const closeModals = () => {
    setDeleteModalOpen(false);
    setUpdateModalOpen(false);
  };

  const formattedData = teamData.map(member => ({
    Profile: (
      <img
        src={member.profile_pic || 'default_profile_pic.png'}
        alt="Profile"
        className="h-10 w-10 rounded-full"
      />
    ),
    id: member._id,
    Name: member.name,
    Role: member.position,
    Email: member.email,
    Status: member.status,
    Actions: (
      <div>
        <button
          onClick={() => handleEdit(member._id)}
          className="text-blue-600 hover:text-blue-800 mr-2"
        >
          Edit
        </button>
        <button
          onClick={() => openDeleteModal(member._id)}
          className="text-red-600 hover:text-red-800"
        >
          Delete
        </button>
      </div>
    )
  }));

  return (
    <div>
      <h1 className="text-3xl font-semibold mb-6">Team Management</h1>
      
      {loading && <p>Loading...</p>} {/* Loading indicator */}

      <form onSubmit={handleSubmit} className="mb-6">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <input
            type="text"
            name="Name"
            value={formData.Name}
            onChange={handleChange}
            placeholder="Name"
            className="border rounded px-4 py-2"
            required
          />
          <input
            type="text"
            name="Role"
            value={formData.Role}
            onChange={handleChange}
            placeholder="Role"
            className="border rounded px-4 py-2"
            required
          />
          <input
            type="email"
            name="Email"
            value={formData.Email}
            onChange={handleChange}
            placeholder="Email"
            className="border rounded px-4 py-2"
            required
          />
          <select
            name="Status"
            value={formData.Status}
            onChange={handleChange}
            className="border rounded px-4 py-2"
          >
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
          </select>
        </div>
        <div className="mt-4">
          <label className="block mb-2">Upload Profile Picture:</label>
          <input
            type="file"
            name="ProfilePic"
            onChange={handleChange}
            accept="image/*"
            className="border rounded px-4 py-2"
          />
          {formData.ProfilePic && (
            <img
              src={URL.createObjectURL(formData.ProfilePic)}
              alt="Profile Preview"
              className="mt-2 h-20 w-20 object-cover rounded-full"
            />
          )}
        </div>
        <button
          type="submit"
          className="mt-4 bg-blue-500 text-white rounded px-4 py-2 hover:bg-blue-600"
        >
          {editMode ? 'Update Team Member' : 'Add Team Member'}
        </button>
      </form>

      <DataTable columns={teamColumns} data={formattedData} />

      <Modal isOpen={updateModalOpen} onClose={closeModals} title="Update Team Member">
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="Name"
            value={formData.Name}
            onChange={handleChange}
            placeholder="Name"
            className="border rounded px-4 py-2 mb-2"
            required
          />
          <input
            type="text"
            name="Role"
            value={formData.Role}
            onChange={handleChange}
            placeholder="Role"
            className="border rounded px-4 py-2 mb-2"
            required
          />
          <input
            type="email"
            name="Email"
            value={formData.Email}
            onChange={handleChange}
            placeholder="Email"
            className="border rounded px-4 py-2 mb-2"
            required
          />
          <select
            name="Status"
            value={formData.Status}
            onChange={handleChange}
            className="border rounded px-4 py-2 mb-2"
          >
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
          </select>
          <button
            type="submit"
            className="bg-blue-500 text-white rounded px-4 py-2 hover:bg-blue-600"
          >
            Update
          </button>
        </form>
      </Modal>

      <Modal isOpen={deleteModalOpen} onClose={closeModals} title="Confirm Deletion">
        <p>Are you sure you want to delete this team member?</p>
        <div className="mt-4">
          <button
            onClick={() => handleDelete(memberToDelete)}
            className="bg-red-500 text-white rounded px-4 py-2 hover:bg-red-600"
          >
            Delete
          </button>
          <button
            onClick={closeModals}
            className="ml-2 bg-gray-500 text-white rounded px-4 py-2 hover:bg-gray-600"
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default TeamManagement;
