import React from 'react';

const ForgotPassword = () => {
  return (
    <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow">
      <h2 className="text-2xl font-bold mb-6 text-center">Forgot Password</h2>
      <form className="space-y-4">
        <input type="email" placeholder="Email" className="w-full p-2 border rounded" />
        <button className="w-full bg-purple-500 text-white p-2 rounded">Reset Password</button>
      </form>
    </div>
  );
};

export default ForgotPassword;
