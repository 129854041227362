// src/components/CreatePackage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';

const CreatePackage = ({ packageData, onClose }) => {
  let URL='https://soaraway-api.zapto.org'
  const [formData, setFormData] = useState({
    name: '',
    category: '',
    location: '',
    imageUrl: '',
    placeName: '',
    price: { single: 0, double: 0 },
    duration: { days: 0, nights: 0 },
    departureDate: '',
    tags: [],
    facility: {
      flight: false,
      hotel: false,
      exploring: false,
      meals: false,
      visa: false,
      insurance: false,
    },
    benefits: {
      saSurprise: false,
      saPriceGuarantee: false,
    },
    itinerary: [{ name: '', details: [''] }],
    inclusive: [],
    exclusive: [],
    paymentPolicy: [],
    cancellationPolicy: [],
    generalNotes: [],
    packageType: 'international', // Default value
  });

  useEffect(() => {
    if (packageData) {
      setFormData({
        ...packageData,
        price: { ...packageData.price },
        duration: { ...packageData.duration },
        tags: packageData.tags || [],
        facility: { ...packageData.facility },
        benefits: { ...packageData.benefits },
        itinerary: packageData.itinerary || [{ name: '', details: [''] }],
      });
    }
  }, [packageData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('facility') || name.includes('benefits')) {
      const [section, key] = name.split('.');
      setFormData((prev) => ({
        ...prev,
        [section]: { ...prev[section], [key]: e.target.checked },
      }));
    } else if (name === 'tags') {
      setFormData({ ...formData, tags: value.split(',') });
    } else if (name.includes('itinerary')) {
      const [section, index, field] = name.split('.');
      const newItinerary = [...formData.itinerary];
      newItinerary[index] = { ...newItinerary[index], [field]: value };
      setFormData({ ...formData, itinerary: newItinerary });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handlePriceChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      price: { ...formData.price, [name]: Number(value) },
    });
  };

  const handleDurationChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      duration: { ...formData.duration, [name]: Number(value) },
    });
  };

  const handleItineraryAdd = () => {
    setFormData({
      ...formData,
      itinerary: [...formData.itinerary, { name: '', details: [''] }],
    });
  };

  const handleItineraryDetailChange = (index, value, detailIndex) => {
    const newItinerary = [...formData.itinerary];
    newItinerary[index].details[detailIndex] = value;
    setFormData({ ...formData, itinerary: newItinerary });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (packageData) {
        await axios.put(`${URL}/package/packages/${packageData._id}`, formData);
      } else {
        await axios.post(`${URL}/package/packages`, formData);
      }
      onClose(); // Close the form after submission
    } catch (error) {
      console.error('Error saving package:', error);
    }
  };

  return (
    <div className="p-6 bg-white shadow-md rounded-lg mt-4">
      <h2 className="text-2xl font-semibold mb-4">{packageData ? 'Edit Package' : 'Create Package'}</h2>
      <form onSubmit={handleSubmit}>
        {/* Basic Info */}
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Package Name"
          className="border border-gray-300 p-2 w-full mb-2"
          required
        />
        <input
          type="text"
          name="category"
          value={formData.category}
          onChange={handleChange}
          placeholder="Category"
          className="border border-gray-300 p-2 w-full mb-2"
          required
        />
        <input
          type="text"
          name="location"
          value={formData.location}
          onChange={handleChange}
          placeholder="Location"
          className="border border-gray-300 p-2 w-full mb-2"
          required
        />
        <input
          type="text"
          name="imageUrl"
          value={formData.imageUrl}
          onChange={handleChange}
          placeholder="Image URL"
          className="border border-gray-300 p-2 w-full mb-2"
        />
        <input
          type="text"
          name="placeName"
          value={formData.placeName}
          onChange={handleChange}
          placeholder="Place Name"
          className="border border-gray-300 p-2 w-full mb-2"
        />
        
        {/* Price */}
        <div className="flex space-x-4 mb-2">
          <input
            type="number"
            name="single"
            value={formData.price.single}
            onChange={handlePriceChange}
            placeholder="Single Price"
            className="border border-gray-300 p-2 w-full"
            required
          />
          <input
            type="number"
            name="double"
            value={formData.price.double}
            onChange={handlePriceChange}
            placeholder="Double Price"
            className="border border-gray-300 p-2 w-full"
            required
          />
        </div>

        {/* Duration */}
        <div className="flex space-x-4 mb-2">
          <input
            type="number"
            name="days"
            value={formData.duration.days}
            onChange={handleDurationChange}
            placeholder="Days"
            className="border border-gray-300 p-2 w-full"
            required
          />
          <input
            type="number"
            name="nights"
            value={formData.duration.nights}
            onChange={handleDurationChange}
            placeholder="Nights"
            className="border border-gray-300 p-2 w-full"
            required
          />
        </div>

        <input
          type="date"
          name="departureDate"
          value={formData.departureDate.split('T')[0]} // Format date correctly
          onChange={handleChange}
          className="border border-gray-300 p-2 w-full mb-2"
          required
        />

        {/* Tags */}
        <input
          type="text"
          name="tags"
          value={formData.tags.join(',')}
          onChange={handleChange}
          placeholder="Tags (comma-separated)"
          className="border border-gray-300 p-2 w-full mb-2"
        />

        {/* Facility */}
        <h3 className="text-lg font-semibold mb-2">Facilities</h3>
        {Object.keys(formData.facility).map((key) => (
          <label key={key} className="inline-flex items-center mr-4">
            <input
              type="checkbox"
              name={`facility.${key}`}
              checked={formData.facility[key]}
              onChange={handleChange}
              className="mr-2"
            />
            {key.charAt(0).toUpperCase() + key.slice(1)}
          </label>
        ))}

        {/* Benefits */}
        <h3 className="text-lg font-semibold mb-2">Benefits</h3>
        {Object.keys(formData.benefits).map((key) => (
          <label key={key} className="inline-flex items-center mr-4">
            <input
              type="checkbox"
              name={`benefits.${key}`}
              checked={formData.benefits[key]}
              onChange={handleChange}
              className="mr-2"
            />
            {key.charAt(0).toUpperCase() + key.slice(1)}
          </label>
        ))}

        {/* Itinerary */}
        <h3 className="text-lg font-semibold mb-2">Itinerary</h3>
        {formData.itinerary.map((item, index) => (
          <div key={index} className="mb-2">
            <input
              type="text"
              name={`itinerary.${index}.name`}
              value={item.name}
              onChange={handleChange}
              placeholder={`Day ${index + 1} Name`}
              className="border border-gray-300 p-2 w-full mb-2"
            />
            {item.details.map((detail, detailIndex) => (
              <input
                key={detailIndex}
                type="text"
                value={detail}
                onChange={(e) => handleItineraryDetailChange(index, e.target.value, detailIndex)}
                placeholder={`Detail ${detailIndex + 1}`}
                className="border border-gray-300 p-2 w-full mb-2"
              />
            ))}
            <button
              type="button"
              onClick={() => handleItineraryDetailChange(index, '', item.details.length)}
              className="bg-blue-500 text-white p-2 rounded"
            >
              Add Detail
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={handleItineraryAdd}
          className="bg-blue-500 text-white p-2 rounded mb-4"
        >
          Add Itinerary Day
        </button>

        {/* Inclusive and Exclusive */}
        <input
          type="text"
          name="inclusive"
          value={formData.inclusive.join(',')}
          onChange={(e) => setFormData({ ...formData, inclusive: e.target.value.split(',') })}
          placeholder="Inclusive (comma-separated)"
          className="border border-gray-300 p-2 w-full mb-2"
        />
        <input
          type="text"
          name="exclusive"
          value={formData.exclusive.join(',')}
          onChange={(e) => setFormData({ ...formData, exclusive: e.target.value.split(',') })}
          placeholder="Exclusive (comma-separated)"
          className="border border-gray-300 p-2 w-full mb-2"
        />

        {/* Policies */}
        <input
          type="text"
          name="paymentPolicy"
          value={formData.paymentPolicy.join(',')}
          onChange={(e) => setFormData({ ...formData, paymentPolicy: e.target.value.split(',') })}
          placeholder="Payment Policy (comma-separated)"
          className="border border-gray-300 p-2 w-full mb-2"
        />
        <input
          type="text"
          name="cancellationPolicy"
          value={formData.cancellationPolicy.join(',')}
          onChange={(e) => setFormData({ ...formData, cancellationPolicy: e.target.value.split(',') })}
          placeholder="Cancellation Policy (comma-separated)"
          className="border border-gray-300 p-2 w-full mb-2"
        />
        <input
          type="text"
          name="generalNotes"
          value={formData.generalNotes.join(',')}
          onChange={(e) => setFormData({ ...formData, generalNotes: e.target.value.split(',') })}
          placeholder="General Notes (comma-separated)"
          className="border border-gray-300 p-2 w-full mb-2"
        />

        {/* Package Type */}
        <select
          name="packageType"
          value={formData.packageType}
          onChange={handleChange}
          className="border border-gray-300 p-2 w-full mb-2"
        >
          <option value="international">International</option>
          <option value="domestic">Domestic</option>
        </select>

        <button type="submit" className="bg-green-500 text-white p-2 rounded">
          {packageData ? 'Update Package' : 'Create Package'}
        </button>
      </form>
    </div>
  );
};

export default CreatePackage;
